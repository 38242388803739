@import '~@angular/material/prebuilt-themes/indigo-pink';
@import "loading";
@import "loading-btn";
@import "variable";

.main {
  min-height: 100vh;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.navbar-custom {
  background-color: #FFF;
}

.form-control {
  border-radius: 5px;
  border: solid 1px #ddd;
}

.main .banner {
  margin-top: 60px;
}